<template>
    <div class='recipes'>
        <div class="basicInfo funMedicine">
            <div class="recBgaimg">
                <img class="img" :src="recipesImg" alt="">
            </div>
            <div class="name">
                <div>姓名：</div>
                <div>{{ foodData.Name }}</div>
            </div>
            <div class="time">
                <div>“ 开方时间：</div>
                <div>{{ foodData.CreateTime }}”</div>
            </div>
        </div>
        <!-- 食疗产品 -->
        <div class="foodProduct funMedicine">
            <!-- 食养果菜汁 -->
            <div class="proTitle">
                <div class="title">
                    <div class="proIcon">
                        <img class="img" :src="foodIcon" alt="">
                    </div>
                    <div>食养果菜汁</div>
                </div>
                <div class="subtitle">FRUIT JUICE</div>
            </div>
            <div class="food">
                <div class="foodItem" v-for="(item, index) in foodData.fruitData" :key="index" :style="{'backgroundColor': index == '0' ? '#A7E2AA' : '#80D9E7'}">
                    <div>{{ item.FoodName }}</div>
                    <div class="tipSty" :style="{'backgroundColor': index == '0' ? '#81C985' : '#5ABAC9'}">
                        <div class="text">
                            0{{ index+1 }}
                        </div>
                    </div>
                </div>
            </div>
            <!-- 食养饮料 -->
            <div class="proTitle">
                <div class="title">
                    <div class="proIcon">
                        <img class="img" :src="foodIcon2" alt="">
                    </div>
                    <div>食养饮料</div>
                </div>
                <div class="subtitle">DRINKS</div>
            </div>
            <div class="food">
                <div class="foodItem" v-for="(item, index) in foodData.drinksData" :key="index" :style="{'backgroundColor': index == '0' ? '#A7E2D2' : '#8C9BE4'}">
                    <div>{{ item.FoodName }}</div>
                    <div class="tipSty" :style="{'backgroundColor': index == '0' ? '#7FBEAD' : '#7C8BD5'}">
                        <div class="text">
                            0{{ index+1 }}
                        </div>
                    </div>
                </div>
            </div>
            <!-- 使用方法 -->
            <div class="usage">
                <div class="translate">Usage time</div>
                <div class="title">
                    <div>食养汤方</div>
                    <div>建议使用时间</div>
                </div>
                <div class="line"></div>
            </div>
            <div class="usageTime">
                <div class="infoTitle">
                    <div class="serial">1</div>
                    <div class="til">食养果蔬汁</div>
                </div>
                <div class="text">每天晚上20:00-20:30；（2个汤方隔天交替使用），每次建议使用300毫升。</div>
            </div>
            <div class="usageTime">
                <div class="infoTitle">
                    <div class="serial">2</div>
                    <div class="til">食养饮料</div>
                </div>
                <div class="text">每天起床时；上午10:00；下午16:00（2个汤方隔天交替使用），每次建议使用500毫升。</div>
            </div>
        </div>
        <!-- 食疗做法 食养果菜汁-->
        <div class="practice funMedicine" v-for="(food, index) in foodData.fruitData" :key="index">
            <div class="foodItem" >
                <div class="foodimg">
                    <img class="img" :src="food.FoodPicture" alt="">
                </div>
                <div class="FoodName">{{ food.FoodName }}</div>
                <div class="step">
                    <div class="stepInfo">
                        <div class="foodIcon">
                            <img class="img" :src="materialIcon" alt="">
                        </div>
                        <div>材料：</div>
                    </div>
                    <div class="material">{{ food.FoodMaterial }}</div>
                </div>
                <div class="step">
                    <div class="stepInfo">
                        <div class="foodIcon">
                            <img class="img" :src="practiceIcon" alt="">
                        </div>
                        <div>做法：</div>
                    </div>
                    <div class="material">{{ food.FoodMethod }}</div>
                </div>
                <div class="step">
                    <div class="stepInfo">
                        <div class="foodIcon">
                            <img class="img" :src="usageIcon" alt="">
                        </div>
                        <div>用法：</div>
                    </div>
                    <div class="material" v-html="food.FoodUseMethod"></div>
                </div>
                <div class="step">
                    <div class="stepInfo">
                        <div class="foodIcon">
                            <img class="img" :src="carefulIcon" alt="">
                        </div>
                        <div>注意事项：</div>
                    </div>
                    <div class="material">{{ food.FoodAttention }}</div>
                </div>
            </div>
        </div>
        <!-- 食疗做法 食养饮料 -->
        <div class="practice funMedicine" v-for="(food, index) in foodData.drinksData" :key="index">
            <div class="foodItem" >
                <div class="foodimg">
                    <img class="img" :src="food.FoodPicture" alt="">
                </div>
                <div class="FoodName">{{ food.FoodName }}</div>
                <div class="step">
                    <div class="stepInfo">
                        <div class="foodIcon">
                            <img class="img" :src="materialIcon" alt="">
                        </div>
                        <div>材料：</div>
                    </div>
                    <div class="material">{{ food.FoodMaterial }}</div>
                </div>
                <div class="step">
                    <div class="stepInfo">
                        <div class="foodIcon">
                            <img class="img" :src="practiceIcon" alt="">
                        </div>
                        <div>做法：</div>
                    </div>
                    <div class="material">{{ food.FoodMethod }}</div>
                </div>
                <div class="step">
                    <div class="stepInfo">
                        <div class="foodIcon">
                            <img class="img" :src="usageIcon" alt="">
                        </div>
                        <div>用法：</div>
                    </div>
                    <div class="material" v-html="food.FoodUseMethod"></div>
                </div>
                <div class="step">
                    <div class="stepInfo">
                        <div class="foodIcon">
                            <img class="img" :src="carefulIcon" alt="">
                        </div>
                        <div>注意事项：</div>
                    </div>
                    <div class="material">{{ food.FoodAttention }}</div>
                </div>
            </div>
        </div>
        <!-- 食养二分法 -->
        <div class="dietTyerapyImg funMedicine">
            <img class="img" style="height: auto !important;" :src="dietTyerapy" alt="">
        </div>
    </div>
</template>

<script>
import { GetFoodWayListById } from '@/api/index'

export default {
  components: {

  },
  data () {
    return {
      recipesImg: `${this.$imgDomain}/www/HealthingCore/dietTyerapy4.png`,
      foodIcon: `${this.$imgDomain}/www/HealthingCore/dietIcon4.png`,
      foodIcon2: `${this.$imgDomain}/www/HealthingCore/dietIcon5.png`,
      materialIcon: `${this.$imgDomain}/www/HealthingCore/foodIcon.png`,
      practiceIcon: `${this.$imgDomain}/www/HealthingCore/foodIcon2.png`,
      usageIcon: `${this.$imgDomain}/www/HealthingCore/foodIcon3.png`,
      carefulIcon: `${this.$imgDomain}/www/HealthingCore/foodIcon4.png`,
      // dietTyerapy: `${this.$imgDomain}/www/HealthingCore/dietTyerapyPdf.png`,
      dietTyerapy: require('../../assets/dietTyerapyPdf.jpg'),
      foodData: {}
    }
  },
  props: {

  },
  created () {
    const id = this.$query('id')
    this.getCustomerFoodWayResult(id)
  },
  mounted () {
  },
  methods: {
    getCustomerFoodWayResult (id) {
      GetFoodWayListById({ id }).then(res => {
        const { data } = res
        if (data) {
          this.foodData = data
        }
      })
    }
  },
  watch: {

  },
  computed: {

  }
}
</script>
<style lang='scss' scoped>
.recipes {
    width: 592.28px;
    .funMedicine {
        height: 860px;
    }
    .basicInfo {
        .recBgaimg {
            width: 100%;
        }
        .name {
            color: #666666;
            font-size: 20px;
            display: flex;
            justify-content: center;
            font-weight: 700;
            margin: 20px 0;
        }
        .time {
            color: #98D5D7;
            font-size: 22px;
            display: flex;
            justify-content: center;
        }
    }
    .foodProduct {
        background-image: url('https://gotocloud8-open-sx.oss-cn-hangzhou.aliyuncs.com/www/HealthingCore/dietTyerapy5.png') no-repeat;
        background-size: 100% 100%;
        padding: 20px;
        margin-top: 40px;
        .proTitle {
            text-align: center;
            .title {
                display: flex;
                justify-content: center;
                align-items: flex-end;
                color: #666666;
                font-size: 24px;
                .proIcon {
                    width: 34px;
                    height: 50px;
                }
            }
            .subtitle {
                color: #D7EEEF;
                font-size: 24px;
                margin-top: 5px;
            }
        }
        .food {
            display: flex;
            justify-content: space-around;
            margin: 20px 0;
            .foodItem {
                width: 40%;
                padding: 0 10px;
                height: 100px;
                color: #fff;
                font-size: 20px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                border-radius: 10px;
                position: relative;
                .tipSty {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    top: -20px;
                    width: 40px;
                    height: 40px;
                    border-radius: 10px;
                    transform: rotate(45deg);
                    .text {
                        transform: rotate(-45deg);
                    }
                }
            }
        }
        .usage {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            .translate {
                font-size: 20px;
                color: #666;
            }
            .title {
                color: #63D6DB;
                font-size: 30px;
                text-align: center;
                margin: 20px 0;
            }
            .line {
                width: 30px;
                height: 5px;
                border-radius: 5px;
                background-color: #63D6DB;
            }
        }
        .usageTime {
            margin-top: 20px;
            .infoTitle {
                width: 130px;
                height: 30px;
                border-radius: 50px;
                background-color: #63D6DB;
                display: flex;
                align-items: center;
                color: #fff;
                font-size: 16px;
                padding: 0 10px 0 5px;
                .serial {
                    width: 20px;
                    height: 20px;
                    border-radius: 10px;
                    background: #fff;
                    color: #63D6DB;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: 700;
                    margin-right: 5px;
                }
                .til {
                    line-height: 19px;
                }
            }
            .text {
                color: #666;
                font-size: 16px;
                line-height: 30px;
                margin: 10px 0;
                // font-weight: 700;
            }
        }
    }
    .practice {
        margin-top: 40px;
        .usage {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            .title {
                color: #63D6DB;
                font-size: 30px;
                text-align: center;
                margin: 20px 0;
            }
            .line {
                width: 30px;
                height: 5px;
                border-radius: 5px;
                background-color: #63D6DB;
            }
        }
        .foodItem {
            .foodimg {
                width: 100%;
                height: 400px;
            }
            .FoodName {
                text-align: center;
                font-size: 18px;
                margin: 20px 0;
            }
            .step {
                padding: 0 20px;
                font-size: 16px;
                margin-bottom: 20px;
                .stepInfo {
                    display: flex;
                    align-items: center;
                    color: #333333;
                    font-size: 18px;
                    font-weight: 700;
                    .foodIcon {
                        width: 18px;
                        margin-right: 5px;
                    }
                }
                .material {
                    margin: 10px 0;
                }
            }
        }
    }
    .dietTyerapyImg {
        margin-top: 40px;
        width: 100%;
    }
}
</style>
